import React from 'react';
import {ProductLineItem} from '@wix/wixstores-client-common-components/dist/es/src/ProductLineItem/ProductLineItem';
import {FormattedItem, PaymentStatus} from '../../../../../types/app.types';
import {LayoutDataHook} from '../Layout/Layout';
import {useControllerProps} from '../../ControllerContext';
import {useTranslation} from 'yoshi-flow-editor-runtime';
import {getSubscriptionDuration} from './utils';
import {classes} from './ProductLineItemOverride.st.css';
import Download from 'wix-ui-icons-common/on-stage/Download';
import {ProductType} from '../../../constants';
import {useStyles} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import stylesParams from '../../../stylesParams';

export const CommonProductLineItem = ({
  item,
  options,
  withDivider,
}: {
  item: FormattedItem;
  options: {
    title: string;
    value: string;
  }[];
  withDivider: boolean;
}) => {
  const {t} = useTranslation();
  const styleSettings = useStyles();
  const shouldRenderImage = styleSettings.get(stylesParams.THANK_YOU_PAGE_IMAGE_SELECTION);

  const {isSubscription, subscriptionName, subscriptionDuration, subscriptionFrequency, paymentStatus} =
    useControllerProps().thankYouPageStore;

  const subscriptionInfo = isSubscription
    ? {
        name: subscriptionName,
        duration: getSubscriptionDuration({
          t,
          subscriptionDuration,
          subscriptionFrequency,
        }),
      }
    : undefined;

  const showDigitalProductDownloadLink =
    item.digitalFileLink && paymentStatus === PaymentStatus.Paid && item.product.productType === ProductType.Digital;

  return (
    <div data-hook={LayoutDataHook.productLineItem} key={item.cartItemId}>
      <ProductLineItem withDivider={withDivider}>
        {shouldRenderImage && <ProductLineItem.Image product={item.product} />}
        <ProductLineItem.Name name={item.product.name} className={classes.myName} />
        <ProductLineItem.Price formattedPrice={item.convertedPrices.formattedPrice} className={classes.myPrice} />
        <ProductLineItem.TotalPrice
          formattedTotalPrice={item.convertedPrices.formattedTotalPrice}
          className={classes.myTotalPrice}
        />
        <ProductLineItem.Options itemId={Number(item.cartItemId)} options={options} className={classes.myOptions} />
        <ProductLineItem.ReadOnlyQuantity
          message={t('thankYouPage.quantity.label')}
          quantity={item.quantity}
          className={classes.myReadOnlyQuantity}
        />
        {!!subscriptionInfo && (
          <ProductLineItem.Subscription
            itemId={Number(item.cartItemId)}
            subscription={subscriptionInfo}
            className={classes.mySubscription}
          />
        )}
        {showDigitalProductDownloadLink && (
          <ProductLineItem.Link
            title={t('thankYou.DOWNLOAD_LINK_LABEL')}
            icon={<Download className={classes.linkIcon} />}
            href={item.digitalFileLink}
            className={classes.myLink}
          />
        )}
      </ProductLineItem>
    </div>
  );
};
